import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.src)
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        style: _normalizeStyle({ maskImage: `url(${_ctx.src})` }),
        class: _normalizeClass(_ctx.$s.root)
      }, null, 6))
    : _createCommentVNode("", true)
}