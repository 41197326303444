import {
  GetTokensApis, INetworkConfig, NetworkChainId, TokenApi,
} from './types'
import {
  sepolia, bsc, mainnet, arbitrum, avalanche, polygon, base, optimism,
} from 'viem/chains'

// TODO: need inspect gasPrice settings for all settings

const ANKR_KEY = process.env.VUE_APP_ANKR_KEY
const ALCHEMY_KEY = process.env.VUE_APP_ALCHEMY_KEY
const BASE_CLOUD_KEY = process.env.VUE_APP_BASE_CLOUD_KEY
const DRPC_KEY = process.env.VUE_APP_DRPC_KEY
const ETHERSCAN_KEY = process.env.VUE_APP_ETHERSCAN_KEY
const ETHPLORER_KEY = process.env.VUE_APP_ETHPLORER_KEY

const ETHERSCAN_API_PARAMS = {
  type: GetTokensApis.ETHERSCAN,
  url: 'https://api.etherscan.io/v2/api',
  query: `apikey=${ETHERSCAN_KEY}&module=account&action=tokentx&address=`,
}

const MULTISENDER_API_PARAMS = {
  type: GetTokensApis.MULTISENDER,
  url: 'https://rpc.multisender.app/api/V9HSbkbGw9J8v64gDqoxauwhyCQjB8NF',
  query: '',
}

const ETHPLORER_API_PARAMS = {
  type: GetTokensApis.ETHPLORER,
  url: 'https://api.ethplorer.io/getAddressInfo',
  query: `apiKey=${ETHPLORER_KEY}`,
}

const ALCHEMY_API_PARAMS = (url: string): TokenApi => ({
  type: GetTokensApis.ALCHEMY,
  url: `https://${url}/v2/${ALCHEMY_KEY}`,
  query: '',
})

const BLOCKSCOUT_API_PARAMS = (url: string) => ({
  type: GetTokensApis.BLOCKSCOUT,
  url: `https://${url}/api`,
  query: 'module=account&action=tokenlist&address=',
})

const ICONS_STATIC_URL = 'https://static.multisender.app/networks/white'

export const NETWORK_MAINNET: INetworkConfig = {
  chain: mainnet,
  network: {
    chainId: NetworkChainId.mainnet,
    name: 'Ethereum Mainnet',
    nameShort: 'Mainnet',
    icon: `${ICONS_STATIC_URL}/ethereum.svg`,
    pollTime: 15,
    EIP1559: true,
    testnet: false,
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/ethereum.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0xA6646162Ab02E9945CeDdf950167F892F682c985',
      blockFrom: 14920219,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 20,
    urls: [
      `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=ethereum&dkey=${DRPC_KEY}`,
      `https://rpc.ankr.com/eth/${ANKR_KEY}`,
    ],
  },
  explorer: {
    url: 'https://etherscan.io',
  },
  tokenApis: [
    ETHPLORER_API_PARAMS,
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('eth.blockscout.com'),
    ALCHEMY_API_PARAMS('eth-mainnet.alchemyapi.io'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_SEPOLIA: INetworkConfig = {
  chain: sepolia,
  network: {
    chainId: NetworkChainId.sepolia,
    name: 'Ethereum Sepolia',
    nameShort: 'Sepolia',
    icon: `${ICONS_STATIC_URL}/ethereum.svg`,
    pollTime: 15,
    EIP1559: true,
    testnet: true,
  },
  currency: {
    name: 'SEP',
    symbol: 'SEP',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/ethereum.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0xAa6E22C688Aafc52195E61555d0eDD65f2412332',
      blockFrom: 5293973,
    },
    multicall3: NETWORK_MAINNET.contracts.multicall3,
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=sepolia&dkey=${DRPC_KEY}`,
      'https://ethereum-sepolia.blockpi.network/v1/rpc/public',
    ],
  },
  explorer: {
    url: 'https://sepolia.etherscan.io',
  },
  tokenApis: [
    BLOCKSCOUT_API_PARAMS('eth-sepolia.blockscout.com'),
    ALCHEMY_API_PARAMS('eth-sepolia.alchemyapi.io'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_BSC: INetworkConfig = {
  chain: bsc,
  network: {
    chainId: NetworkChainId.binance,
    nameShort: 'BSC',
    name: 'BNB Smart Chain Mainnet',
    pollTime: 15,
    EIP1559: false,
    icon: `${ICONS_STATIC_URL}/bnb-smart-chain.svg`,
    testnet: false,
  },
  currency: {
    name: 'BNB Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/bnb-smart-chain.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0x00fe66030962387441a6Fdb32A593677752f71b7',
      blockFrom: 18477930,
    },
    multicall3: NETWORK_MAINNET.contracts.multicall3,
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://bnb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=bsc&dkey=${DRPC_KEY}`,
      `https://rpc.ankr.com/bsc/${ANKR_KEY}`,
      'https://bsc-dataseed1.ninicoin.io',
    ],
  },
  explorer: {
    url: 'https://bscscan.com',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    ALCHEMY_API_PARAMS('bnb-mainnet.alchemyapi.io'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_ARBITRUM: INetworkConfig = {
  chain: arbitrum,
  network: {
    chainId: NetworkChainId.arbitrum,
    name: 'Arbitrum One',
    nameShort: 'Arbitrum One',
    icon: `${ICONS_STATIC_URL}/arbitrum-one.svg`,
    pollTime: 15,
    EIP1559: true,
    testnet: false,
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/arbitrum-one.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 258600834,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://arb-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=arbitrum&dkey=${DRPC_KEY}`,
      `https://rpc.ankr.com/arbitrum/${ANKR_KEY}`,
    ],
  },
  explorer: {
    url: 'https://arbiscan.io/',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('arbitrum.blockscout.com'),
    ALCHEMY_API_PARAMS('arb-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_AVALANCHE: INetworkConfig = {
  chain: avalanche,
  network: {
    chainId: NetworkChainId.avalanche,
    name: 'Avalanche C-Chain',
    nameShort: 'Avalanche',
    icon: `${ICONS_STATIC_URL}/avalanche-chain.svg`,
    pollTime: 15,
    EIP1559: true,
    testnet: false,
  },
  currency: {
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/avalanche-chain.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 51147517,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://avax-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=avalanche&dkey=${DRPC_KEY}`,
      `https://rpc.ankr.com/avalanche/${ANKR_KEY}`,
    ],
  },
  explorer: {
    url: 'https://snowtrace.io/',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    ALCHEMY_API_PARAMS('avax-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_POLYGON: INetworkConfig = {
  chain: polygon,
  network: {
    chainId: NetworkChainId.polygon,
    name: 'Polygon Mainnet',
    nameShort: 'Polygon',
    icon: `${ICONS_STATIC_URL}/polygon-chain.svg`,
    pollTime: 15,
    EIP1559: true,
    testnet: false,
  },
  currency: {
    name: 'POL',
    symbol: 'POL',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/polygon-chain.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 62423049,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=polygon&dkey=${DRPC_KEY}`,
      `https://rpc.ankr.com/polygon/${ANKR_KEY}`,
    ],
  },
  explorer: {
    url: 'https://polygonscan.com',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('polygon.blockscout.com'),
    ALCHEMY_API_PARAMS('polygon-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_BASE: INetworkConfig = {
  chain: base,
  network: {
    chainId: NetworkChainId.base,
    name: 'Base',
    nameShort: 'Base',
    icon: `${ICONS_STATIC_URL}/base.svg`,
    pollTime: 15,
    EIP1559: true,
    testnet: false,
    opStack: true,
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/base.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 20414967,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://base-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://api.developer.coinbase.com/rpc/v1/base/${BASE_CLOUD_KEY}`,
      `https://rpc.ankr.com/base/${ANKR_KEY}`,
    ],
  },
  explorer: {
    url: 'https://basescan.org',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    ALCHEMY_API_PARAMS('base-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_OPTIMISM: INetworkConfig = {
  chain: optimism,
  network: {
    chainId: NetworkChainId.optimism,
    name: 'Optimism',
    nameShort: 'Optimism',
    icon: `${ICONS_STATIC_URL}/optimism.svg`,
    pollTime: 15,
    EIP1559: true,
    opStack: true,
    testnet: false,
  },
  currency: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    icon: `${ICONS_STATIC_URL}/optimism.svg`,
  },
  contracts: {
    multisenderMerkle: {
      address: '0x25f1fdbD4e12C5FBDE4aB4A4F3BE92f19B13997c',
      blockFrom: 126010508,
    },
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  rpc: {
    retryAttempt: 15,
    urls: [
      `https://opt-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
      `https://lb.drpc.org/ogrpc?network=optimism&dkey=${DRPC_KEY}`,
      `https://rpc.ankr.com/optimism/${ANKR_KEY}`,
    ],
  },
  explorer: {
    url: 'https://optimistic.etherscan.io',
  },
  tokenApis: [
    MULTISENDER_API_PARAMS,
    BLOCKSCOUT_API_PARAMS('optimism.blockscout.com'),
    ALCHEMY_API_PARAMS('opt-mainnet.g.alchemy.com'),
    ETHERSCAN_API_PARAMS,
  ],
  blockGasLimit: 30_000_000,
}

export const NETWORK_CONFIGS = [
  NETWORK_MAINNET,
  NETWORK_BSC,
  NETWORK_ARBITRUM,
  NETWORK_AVALANCHE,
  NETWORK_SEPOLIA,
  NETWORK_POLYGON,
  NETWORK_BASE,
  NETWORK_OPTIMISM,
].reduce((acc, settings) => {
  const { chainId } = settings.network
  acc[chainId] = settings
  return acc
}, {} as Record<NetworkChainId, INetworkConfig>)
