import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIconStatic = _resolveComponent("MIconStatic")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_component_MButton, {
    type: "outline",
    class: _normalizeClass(_ctx.$s.root),
    name: "network",
    disabled: _ctx.isDisabled,
    onClick: _ctx.onClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MIconStatic, {
        src: _ctx.networkIcon,
        class: _normalizeClass([_ctx.$s.icon, `is-network--${_ctx.networkShortName}`])
      }, null, 8, ["src", "class"]),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$s.name),
        textContent: _toDisplayString(_ctx.networkShortName)
      }, null, 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["class", "disabled", "onClick"]))
}