<template>
  <span v-if="src" :style="{ maskImage: `url(${src})` }" :class="$s.root" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    src: {
      type: String,
    },
  },
})
</script>

<style lang="scss" module="$s">
.root {
  display: inline-block;
}
</style>

